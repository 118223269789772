import fastdom from "fastdom";
import { generateId, createJsxFactory, createRuleFactory, createComputedFactory, createVarFactory, createNestedQueryFactory, createUtilsFactory, objectToCss } from ".";
const REUSE_STYLESHEET = {};
function getExportedIdentifiers(result) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    className,
    element,
    extend,
    functions,
    meta,
    toggle,
    specify,
    ...rest
  } = result;
  return rest;
}
const SPECIFY_SELECTOR_SPLIT = /,(?![^(]*\))/;
function specifySelector(id, specificIds, selector) {
  const selectors = selector.indexOf(",") === -1 ? [selector] : selector.split(SPECIFY_SELECTOR_SPLIT);
  const newSelectors = [];
  for (const selector of selectors) {
    newSelectors.push(selector);
    if (selector.startsWith(`.${id}`)) {
      for (const specificId of specificIds) {
        newSelectors.push(`#${specificId} ${selector}`);
      }
    }
  }
  return newSelectors.join(",");
}
const createPureScopedStylesheet = function (callback) {
  let settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let {
    element: parentElement,
    id: parentId,
    inc: parentInc,
    varsVal: parentVarsVal,
    extended: parentExtended,
    specifiedIds: parentSpecifiedIds,
    plugins: parentPlugins,
    toggle: parentToggle,
    specify: parentSpecify,
    detached: extendDetached
  } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const {
    reuse
  } = settings;
  if (reuse && !parentId && REUSE_STYLESHEET[reuse]) {
    return REUSE_STYLESHEET[reuse];
  }
  const extended = parentExtended || {};
  const specifiedIds = parentSpecifiedIds || [];
  const id = parentId ? `${parentId}-ext-${Object.getOwnPropertySymbols(extended).length}` : generateId(4);
  const element = document.createElement("style");
  element.setAttribute("skip-rucss", "true");
  const meta = {
    inc: parentInc || 1,
    // not using `0` as it is considered falsy in conditions,
    id,
    varsVal: parentVarsVal || new Map(),
    settings,
    plugins: parentPlugins || {
      filterClassName: [settings.filterClassName].filter(Boolean),
      modifyRule: [settings.modifyRule].filter(Boolean)
    },
    runPlugin: function (name) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      for (const p of meta.plugins[name]) {
        p(...args);
      }
    },
    className: `.${id}`,
    rules: new Map(),
    isExtension: !!parentId,
    element,
    mainElement: parentElement || element,
    specifiedIds,
    extended
  };
  const toggle = parentToggle || (active => fastdom.mutate(() => {
    const {
      element
    } = meta;
    const [head] = document.getElementsByTagName("head");

    // Collect all toggle-able elements including extended stylesheets
    const extendedElements = Object.getOwnPropertySymbols(extended).map(sym => extended[sym].element);
    const elements = [element, ...extendedElements];
    for (const e of elements) {
      if (active) {
        head.appendChild(e);
      } else {
        head.removeChild(e);
      }
    }
  }));
  const specify = parentSpecify || (specificId => {
    if (specifiedIds.indexOf(specificId) > -1) {
      return;
    }
    specifiedIds.push(specificId);
    fastdom.mutate(() => {
      const regexp = new RegExp(`^[ ]*(\\.${id}.*) {`, "gm");
      const replacer = (_, selector) => `${specifySelector(id, [specificId], selector)} {`;
      for (const element of [meta.mainElement, ...Object.getOwnPropertySymbols(extended).map(sym => extended[sym].element)]) {
        const {
          textContent
        } = element;
        element.textContent = textContent.replace(regexp, replacer);
      }
    });

    // TODO: should also work for all newly created specifies
    // TODO: needs also work for nested rules
  });

  // Activate once
  toggle(true);
  const varFactory = createVarFactory(meta, extendDetached);
  const ruleFactory = createRuleFactory(meta, varFactory);
  const computed = createComputedFactory(meta, varFactory);
  const nestedQuery = createNestedQueryFactory(meta, varFactory);
  const utilsFactory = createUtilsFactory(meta, varFactory);
  const jsxFactory = createJsxFactory(meta, ruleFactory);
  const passFunctions = {
    ...ruleFactory,
    ...varFactory,
    ...utilsFactory,
    ...jsxFactory,
    nestedQuery,
    computed,
    plugin: (name, callback) => {
      meta.plugins[name].push(callback);
    }
  };
  const result = callback({
    meta,
    ...passFunctions
  });
  fastdom.mutate(() => {
    element.textContent = objectToCss(meta.rules);
  });

  // Allow to extend the stylesheet with the same meta descriptors
  const thisInc = meta.inc;
  const extend = function (symbol, callback, detached) {
    let chain = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    const {
      extended,
      mainElement
    } = meta;

    // Compose extended results so `.extend` can be chained
    const useResult = Object.assign({
      _chain: chain
    }, result, ...chain.map(sym => getExportedIdentifiers(extended[sym])));
    if (!extended[symbol]) {
      extended[symbol] = createPureScopedStylesheet(args => callback(args, useResult), settings, {
        toggle,
        detached: detached || false,
        ...meta,
        // When detached, we need to ensure that increment starts from the same point
        inc: detached ? thisInc : meta.inc
      });
      const alreadyExported = Object.keys(useResult);
      for (const exported of Object.keys(getExportedIdentifiers(extended[symbol]))) {
        if (alreadyExported.indexOf(exported) > -1) {
          console.warn(`"${exported}" detected in multiple stylesheets. This will lead to side effects.`);
        }
      }

      // Attach to DOM if parent is also attached to DOM
      if (mainElement.isConnected) {
        toggle(true);
      }
    }
    if (chain.indexOf(symbol) === -1) {
      chain.push(symbol);
    }
    const chainedExtend = (argSymbol, argCallback, argDetached) => extend(argSymbol, argCallback, argDetached, chain);
    return {
      ...useResult,
      ...extended[symbol],
      extend: chainedExtend
    };
  };
  const returnResult = {
    ...result,
    meta,
    element: meta.element,
    className: meta.id,
    specify,
    toggle,
    extend,
    functions: passFunctions
  };
  if (reuse && !parentId) {
    REUSE_STYLESHEET[reuse] = returnResult;
  }
  return returnResult;
};

/**
 * Create a `style` from a given string. If you pass a `unique` it will override the stylesheet
 * content when already exist.
 */
const createStylesheet = function (css, unique) {
  let footer = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const {
    id: uniqueId,
    overwrite = true
  } = typeof unique === "string" ? {
    id: unique
  } : unique || {};
  const id = `pure-css-stylesheet-${uniqueId || generateId(5)}`;
  let element = document.getElementById(id);
  if (!element) {
    element = document.createElement("style");
    element.setAttribute("skip-rucss", "true");
    element.style.type = "text/css";
    element.id = id;
    fastdom.mutate(() => {
      document.getElementsByTagName(footer ? "body" : "head")[0].appendChild(element);
    });
  } else if (!overwrite) {
    return element.remove;
  }
  element.innerHTML = css;
  return element.remove;
};
export { specifySelector, createPureScopedStylesheet, createStylesheet };