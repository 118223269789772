import { mapStringToBoolean, cssRgba, createRenderedDimensionVariables } from "@devowl-wp/web-scoped-css";
const extendBannerStylesheet = [Symbol("extendBannerStylesheet"), (_ref, _ref2) => {
  let {
    computed,
    rule,
    boolIf,
    boolNot,
    boolSwitch,
    jsx,
    variable,
    className,
    vars,
    varName,
    plugin
  } = _ref;
  let {
    unsetDialogStyles,
    customize: {
      activeAction: activeActionValue,
      pageRequestUuid4
    },
    boolLargeOrMobile,
    isMobile,
    layout: {
      dialogPosition,
      dialogMaxWidth,
      dialogMargin,
      dialogBorderRadius,
      bannerPosition,
      overlay,
      overlayBg,
      overlayBgAlpha,
      overlayBlur
    },
    design: {
      boxShadowEnabled,
      boxShadowColor,
      boxShadowOffsetX,
      boxShadowOffsetY,
      boxShadowBlurRadius,
      boxShadowSpreadRadius,
      boxShadowColorAlpha,
      fontSize,
      borderWidth,
      borderColor,
      textAlign,
      fontInheritFamily,
      fontFamily,
      fontWeight,
      fontColor
    },
    mobile,
    layout,
    individualLayout
  } = _ref2;
  // Generate unique IDs for accessibility
  const a11yIds = {
    headline: className(),
    description: className(),
    firstButton: className()
  };

  // Banner-specific variables
  const dimsOverlay = createRenderedDimensionVariables(varName, vars);
  const dimsContent = createRenderedDimensionVariables(varName, vars);
  const dimsHeader = createRenderedDimensionVariables(varName, vars);
  const dimsFooter = createRenderedDimensionVariables(varName, vars);
  const dimsRightSidebar = createRenderedDimensionVariables(varName, vars);
  const activeAction = variable(activeActionValue, mapStringToBoolean(activeActionValue, ["history"]));
  const individualPrivacyOpen = variable(false, undefined, "individual");

  // Force banner on mobile view
  const {
    isDialog,
    isBanner
  } = computed([isMobile, layout.type], _ref3 => {
    let [isMobile, layout] = _ref3;
    const use = isMobile ? "banner" : layout;
    return {
      type: use,
      isDialog: use === "dialog",
      isBanner: use === "banner"
    };
  });
  const alignCpt = computed([dialogPosition], _ref4 => {
    let [dialogPosition] = _ref4;
    return [dialogPosition.startsWith("top") ? "flex-start" : dialogPosition.startsWith("bottom") ? "flex-end" : "center", dialogPosition.endsWith("Center") ? "center" : dialogPosition.endsWith("Left") ? "flex-start" : "flex-end"];
  }, "dialogAlign");
  let backdropFilter = "none";
  if (process.env.PLUGIN_CTX === "pro") {
    /* Silence is golden... Until you have PRO Version! */
  }
  const [Overlay] = jsx("div", {
    classNames: "bann3r",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999999,
    filter: "none",
    maxWidth: "100vw",
    maxHeight: "100vh",
    fontSize: fontSize("l"),
    background: boolIf(overlay, cssRgba(overlayBg, overlayBgAlpha)),
    pointerEvents: boolIf(overlay, "all", "none"),
    backdropFilter
  });
  const [Dialog] = jsx("dialog", {
    classNames: ["align", unsetDialogStyles],
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: boolIf(isDialog, alignCpt(0)),
    justifyContent: boolIf(isDialog, alignCpt(1))
  }, {
    tabIndex: 0,
    "aria-labelledby": a11yIds.headline,
    "aria-describedby": a11yIds.description
  });

  // Inner container
  const width = boolIf({
    when: isDialog,
    then: {
      when: [individualPrivacyOpen, boolNot(individualLayout.inheritDialogMaxWidth)],
      then: individualLayout.dialogMaxWidth(),
      or: dialogMaxWidth()
    },
    or: "100%"
  });
  const {
    alignment
  } = mobile;
  const border = `${boolLargeOrMobile(borderWidth, boolIf)} solid ${borderColor()}`;
  const [, inner] = rule({
    classNames: "inner",
    pointerEvents: "all",
    transition: "width 500ms, max-width 500ms",
    overflow: "hidden",
    maxWidth: width,
    width,
    textAlign: textAlign("val"),
    fontFamily: boolIf(fontInheritFamily, "inherit", fontFamily()),
    fontWeight: fontWeight(),
    color: fontColor("hex"),
    margin: boolIf({
      when: [isDialog, boolNot(dialogPosition["is-middlecenter"])],
      then: dialogMargin(),
      or: boolIf(isDialog, "10px")
    }),
    borderRadius: boolIf(isDialog, dialogBorderRadius()),
    boxShadow: boolIf(boxShadowEnabled, `${boxShadowOffsetX()} ${boxShadowOffsetY()} ${boxShadowBlurRadius()} ${boxShadowSpreadRadius()} rgba(${boxShadowColor("r")} ${boxShadowColor("g")} ${boxShadowColor("b")} / ${boxShadowColorAlpha()})`),
    alignSelf: boolIf({
      when: isMobile,
      then: boolSwitch([[alignment("is-bottom"), "flex-end"], [alignment("is-top"), "flex-start"], [alignment("is-center"), "center"]]),
      or: {
        when: isBanner,
        then: boolSwitch([[bannerPosition("is-top"), "flex-start"], [bannerPosition("is-bottom"), "flex-end"]])
      }
    }),
    borderTop: boolIf({
      when: [isBanner, bannerPosition("is-bottom")],
      then: border
    }),
    borderBottom: boolIf({
      when: [isBanner, bannerPosition("is-top")],
      then: border
    })
  });
  const forceResetRules = {
    boxSizing: "border-box",
    backfaceVisibility: "initial",
    textTransform: "initial",
    // See https://stackoverflow.com/a/2711132/5506547
    "-webkit-text-size-adjust": "100%"
  };
  rule({
    forceSelector: `#${pageRequestUuid4}, #${pageRequestUuid4} *`,
    ...forceResetRules
  });
  plugin("modifyRule", props => {
    if (props.all === "unset") {
      Object.assign(props, forceResetRules);
    }
  });
  return {
    a11yIds,
    isDialog,
    isBanner,
    activeAction,
    dimsOverlay,
    dimsContent,
    dimsHeader,
    dimsFooter,
    dimsRightSidebar,
    individualPrivacyOpen,
    Dialog,
    Overlay,
    inner
  };
}];
export { extendBannerStylesheet };