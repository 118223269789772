const extendCommonButtonsStylesheet = [Symbol("extendCommonButtonsStylesheet"), (_ref, _ref2) => {
  let {
    className,
    jsx,
    boolIf,
    boolSwitch,
    boolOr
  } = _ref;
  let {
    screenReaderOnlyClass,
    isTcf,
    decision,
    layout,
    saveButton,
    bodyDesign,
    design,
    boolLargeOrMobile
  } = _ref2;
  const framed = className();
  const forceShowButton = className();
  const sticky = className();
  const [acceptAll, acceptEssentials, acceptIndividual, save] = ["acceptAll", "acceptEssentials", "acceptIndividual", "save"].map(style => {
    const isSaveButton = style === "save";
    const type = isSaveButton ? saveButton["type"] : decision[style];
    const bg = isSaveButton ? saveButton["bg"] : bodyDesign[`${style}Bg`];
    const hoverBg = isSaveButton ? saveButton["hoverBg"] : bodyDesign[`${style}HoverBg`];
    const padding = isSaveButton ? saveButton["padding"] : bodyDesign[`${style}Padding`];
    const fontSize = isSaveButton ? saveButton["fontSize"] : bodyDesign[`${style}FontSize`];
    const textAlign = isSaveButton ? saveButton["textAlign"] : bodyDesign[`${style}TextAlign`];
    const fontWeight = isSaveButton ? saveButton["fontWeight"] : bodyDesign[`${style}FontWeight`];
    const fontColor = isSaveButton ? saveButton["fontColor"] : bodyDesign[`${style}FontColor`];
    const hoverFontColor = isSaveButton ? saveButton["hoverFontColor"] : bodyDesign[`${style}HoverFontColor`];
    const borderColor = isSaveButton ? saveButton["borderColor"] : bodyDesign[`${style}BorderColor`];
    const hoverBorderColor = isSaveButton ? saveButton["hoverBorderColor"] : bodyDesign[`${style}HoverBorderColor`];
    const borderWidth = isSaveButton ? saveButton["borderWidth"] : bodyDesign[`${style}BorderWidth`];
    const fontSizeBool = boolLargeOrMobile(fontSize, boolIf);
    const boolTcfOrOneRowLayout = boolOr([isTcf, bodyDesign.acceptAllOneRowLayout]);
    const [JsxButton] = jsx("a", {
      classNames: `btn-${style.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)}`,
      all: "unset",
      display: "flex",
      ...(isSaveButton ? {} : {
        display: boolIf(type("is-hide"), "none", "flex")
      }),
      borderRadius: layout.borderRadius(),
      overflow: "hidden",
      textAlign: textAlign("val"),
      color: fontColor(),
      transition: "background-color 250ms, color 250ms, border-color 250ms",
      cursor: boolIf(type("is-button"), "pointer"),
      textDecoration: boolIf(type("is-link"), "underline"),
      backgroundColor: boolIf(type("is-button"), bg()),
      borderStyle: "solid",
      borderColor: borderColor(),
      borderWidth: boolIf(type("is-button"), boolLargeOrMobile(borderWidth, boolIf), "0px"),
      flex: "1 1 100%",
      alignItems: "center",
      // text-align is not supported within a `display: flex`
      justifyContent: boolSwitch([[textAlign("is-right"), "flex-end"], [textAlign("is-center"), "center"]], "left"),
      pointerEvents: boolIf(type("is-link"), "none"),
      ...(["acceptEssentials", "save"].indexOf(style) > -1 ? {
        // For two-button row layout and TCF use all dimensional properties from "Accept all"
        fontSize: boolIf(boolTcfOrOneRowLayout, boolLargeOrMobile(bodyDesign.acceptAllFontSize, boolIf), fontSizeBool),
        fontWeight: boolIf(boolTcfOrOneRowLayout, bodyDesign.acceptAllFontWeight(), fontWeight()),
        padding: boolIf(boolTcfOrOneRowLayout, bodyDesign.acceptAllPadding("l"), padding("l"))
      } : {
        fontSize: fontSizeBool,
        fontWeight: fontWeight(),
        padding: padding("l")
      }),
      pseudos: {
        [`.${forceShowButton}`]: {
          display: "block"
        },
        ">span": {
          cursor: boolIf(type("is-link"), "pointer"),
          pointerEvents: boolIf(type("is-link"), "all")
        },
        [`.${framed}`]: {
          outline: "rgb(255, 94, 94) solid 5px"
        },
        [`.${sticky}`]: {
          position: "sticky",
          bottom: "6px",
          boxShadow: `0px 10px 0px 0px ${design.bg()}, 0px -10px 0px ${design.bg()}`
        },
        ":hover": {
          color: hoverFontColor(),
          backgroundColor: boolIf(type("is-button"), hoverBg()),
          borderColor: hoverBorderColor()
        }
      }
    }, {
      href: "#"
    });
    return JsxButton;
  });
  const [A11ySkipToLink] = jsx("a", {
    classNames: ["skip-to", screenReaderOnlyClass],
    pseudos: {
      ":focus-visible": {
        clip: "initial",
        width: "initial",
        height: "initial",
        zIndex: 10,
        left: "10px",
        top: "10px",
        padding: "5px 10px",
        background: "black",
        color: "white",
        fontSize: "14px"
      }
    }
  }, {
    onClick: e => {
      e.preventDefault();
      document.getElementById(e.target.getAttribute("href").substr(1)).focus();
    }
  });
  return {
    A11ySkipToLink,
    framed,
    sticky,
    forceShowButton,
    buttons: {
      acceptAll,
      acceptEssentials,
      acceptIndividual,
      save
    }
  };
}];
export { extendCommonButtonsStylesheet };