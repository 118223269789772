import { useBanner, BannerHeader, BannerFooter, BannerBody, useBannerStylesheet } from "../../..";
import { useEffect } from "react";
import { useRef } from "react";
import { extendBannerContentStylesheet, extendCommonButtonsStylesheet } from "@devowl-wp/web-cookie-banner";
const BannerContent = () => {
  const {
    Content,
    hideOnMobileClass,
    dimsContent,
    dimsOverlay,
    dimsHeader,
    dimsFooter,
    dimsRightSidebar,
    A11ySkipToLink,
    a11yIds: {
      firstButton
    }
  } = useBannerStylesheet().extend(...extendCommonButtonsStylesheet).extend(...extendBannerContentStylesheet);
  const {
    decision: {
      acceptAll,
      acceptEssentials,
      showCloseIcon
    },
    mobile,
    individualPrivacyOpen,
    bodyDesign: {
      acceptEssentialsUseAcceptAll
    },
    activeAction,
    pageRequestUuid4,
    i18n: {
      skipToConsentChoices
    }
  } = useBanner();
  const ref = useRef();
  const useAcceptEssentials = acceptEssentialsUseAcceptAll && acceptAll === acceptEssentials ? acceptAll : acceptEssentials;
  const headerClassName = mobile.hideHeader && !activeAction && !individualPrivacyOpen && (useAcceptEssentials === "hide" ? !showCloseIcon : true) ? hideOnMobileClass : "";
  const headerRef = useRef();
  const footerRef = useRef();
  const rightSideContainerRef = useRef();
  useEffect(() => {
    const animationContainer = [document.querySelector(`#${pageRequestUuid4} div[class*="animate__"]`)];
    const disposeFns = [dimsContent[0](ref.current), dimsOverlay[0](document.querySelector(`#${pageRequestUuid4}`), animationContainer), dimsHeader[0](headerRef.current, animationContainer), dimsFooter[0](footerRef.current, animationContainer), dimsRightSidebar[0](rightSideContainerRef.current, animationContainer)];
    return () => disposeFns.forEach(c => c());
  }, [ref.current, headerRef.current, footerRef.current, rightSideContainerRef.current]);
  useEffect(() => {
    // Scroll to top automatically at first visit (e.g. `<dialog` auto focuses the first found element)
    ref.current.scrollTop = 0;
  }, [individualPrivacyOpen]);
  return h(Content, {
    ref: ref
  }, h(A11ySkipToLink, {
    href: `#${firstButton}`
  }, skipToConsentChoices), h(BannerHeader, {
    ref: headerRef,
    className: headerClassName
  }), h(BannerBody, {
    rightSideContainerRef: rightSideContainerRef
  }), h(BannerFooter, {
    ref: footerRef
  }));
};
export { BannerContent };