const extendBannerBodyStylesheet = [Symbol("extendBannerBodyStylesheet"), (_ref, _ref2) => {
  let {
    boolIf,
    boolNot,
    boolOr,
    jsx
  } = _ref;
  let {
    scaleVertical,
    dimsContent,
    dimsHeader: [, dimsHeaderVars],
    dimsFooter: [, dimsFooterVars],
    activeAction,
    boolLargeOrMobile,
    bodyDesign,
    isBanner,
    isDialog,
    isMobile,
    isMobileWidth,
    layout,
    individualLayout,
    individualPrivacyOpen,
    design
  } = _ref2;
  const {
    fontColor
  } = design;
  const {
    padding
  } = bodyDesign;
  const [, {
    scrollbar,
    scrolledBottom
  }] = dimsContent;
  const floatingRight = boolOr([isBanner, individualPrivacyOpen]);
  const buttonWidth = "300px";
  const widthRight = boolIf(isBanner, buttonWidth, `calc(${layout.dialogMaxWidth()} - ${boolLargeOrMobile(padding, boolIf, 1)} - ${boolLargeOrMobile(padding, boolIf, 3)} - (${boolLargeOrMobile(design.borderWidth, boolIf)} * 2))`);
  const isDesktopWidth = boolNot(isMobileWidth);
  const separatorBorderSide = boolIf(isDialog, `${boolLargeOrMobile(design.borderWidth, boolIf)} solid ${design.borderColor()}`);
  const [Container] = jsx("div", {
    classNames: "body-container",
    background: design.bg(),
    lineHeight: 1.4,
    // Top / Bottom padding is applied in leftSide and rightSide containers
    paddingRight: boolLargeOrMobile(bodyDesign.padding, boolIf, 1),
    paddingLeft: boolLargeOrMobile(bodyDesign.padding, boolIf, 3),
    borderLeft: separatorBorderSide,
    borderRight: separatorBorderSide,
    pseudos: {
      ">div": {
        transition: "width 500ms, max-width 500ms",
        margin: "auto",
        maxWidth: boolIf({
          when: isBanner,
          then: {
            when: [individualPrivacyOpen, boolNot(individualLayout.inheritBannerMaxWidth)],
            then: individualLayout.bannerMaxWidth(),
            or: layout.bannerMaxWidth()
          }
        })
      },
      ">div:after": {
        content: "''",
        display: "block",
        clear: "both"
      }
    }
  });
  const acceptAllOneRowLayoutFlex = boolIf(bodyDesign.acceptAllOneRowLayout, "0 0 calc(50% - 5px)", "1 1 100%");
  const acceptAllOneRowLayoutMargin = boolIf(bodyDesign.acceptAllOneRowLayout, "5px");
  const [RightSide] = jsx("div", {
    classNames: "tb-right",
    position: boolIf(boolOr([boolNot(individualPrivacyOpen), isMobileWidth]), "sticky"),
    margin: boolIf({
      when: [isDesktopWidth, isDialog, individualPrivacyOpen, boolNot(individualLayout.inheritDialogMaxWidth)],
      then: "0 0 10px 10px"
    }),
    background: design.bg(),
    maxWidth: "100%",
    width: boolIf(isDesktopWidth, widthRight, "auto"),
    float: boolIf(isDesktopWidth, boolIf({
      when: floatingRight,
      then: "right"
    })),
    paddingTop: boolIf(isMobileWidth, "10px", boolIf({
      when: floatingRight,
      then: boolLargeOrMobile(padding, boolIf, 0),
      or: "10px"
    })),
    paddingBottom: boolIf(individualPrivacyOpen, boolIf(isMobileWidth, "5px", "10px"), boolLargeOrMobile(padding, boolIf, 2)),
    zIndex: 1,
    // Avoid overlapping the Checkbox Icon
    display: "flex",
    flexWrap: "wrap",
    transition: "box-shadow ease-in-out .1s",
    boxShadow: boolIf({
      when: [scrollbar(), boolNot(scrolledBottom)],
      then: `0 -15px 15px -15px rgba(${fontColor("r")} ${fontColor("g")} ${fontColor("b")} / 30%)`
    }),
    bottom: boolIf(boolNot(individualPrivacyOpen), dimsFooterVars.height()),
    top: boolIf(individualPrivacyOpen, dimsHeaderVars.height()),
    pseudos: {
      // Modify buttons (e.g. one-row layout)
      ">a": {
        marginBottom: boolIf(isMobile, `calc(10px * ${scaleVertical()})`, "10px")
      },
      ">a[data-order='0']": {
        flex: acceptAllOneRowLayoutFlex,
        marginRight: acceptAllOneRowLayoutMargin
      },
      ">a[data-order='1']": {
        flex: acceptAllOneRowLayoutFlex,
        marginLeft: acceptAllOneRowLayoutMargin
      }
    }
  });
  const floatingLeft = [isDesktopWidth, isBanner, boolNot(activeAction["is-history"])];
  const [LeftSide] = jsx("div", {
    classNames: "tb-left",
    float: boolIf({
      when: floatingLeft,
      then: "left"
    }),
    width: boolIf({
      when: floatingLeft,
      then: `calc(100% - ${buttonWidth})`
    }),
    paddingRight: boolIf({
      when: [isBanner, isDesktopWidth],
      then: "20px"
    }),
    paddingTop: boolIf(individualPrivacyOpen, "10px", boolLargeOrMobile(bodyDesign.padding, boolIf, 0)),
    paddingBottom: boolIf({
      when: [isDesktopWidth, boolNot(individualPrivacyOpen)],
      then: {
        when: boolOr([isBanner, individualPrivacyOpen]),
        then: boolLargeOrMobile(bodyDesign.padding, boolIf, 2),
        or: "0px"
      },
      or: "10px"
    }),
    pseudos: {
      // Make images responsive
      " img": {
        maxWidth: "100%",
        height: "auto"
      }
    }
  });
  return {
    Container,
    RightSide,
    LeftSide
  };
}];
export { extendBannerBodyStylesheet };