import { fastdom } from "@devowl-wp/web-scoped-css";
import { useCallback } from "react";
import { useBannerAnimation, resetConsentChangeHash } from "../..";
/**
 * Provides a callback which can be used to update the state of the banner context and manipulate
 * the `visible` and `animationVisible` property correctly.
 */
function useBannerToggleOverlay(overlay) {
  const useBannerAnimationCallback = useBannerAnimation();
  const toggleOverlay = useCallback((_ref, show, setContextValue) => {
    let {
      layout
    } = _ref;
    const {
      animationInDuration,
      animationOutDuration
    } = layout;
    const {
      useAnimationIn,
      useAnimationOut
    } = useBannerAnimationCallback(layout);
    const defaultIfNoAnimation = 0; // Avoid "hard" overlay
    const useDuration = show ? useAnimationIn === "none" ? defaultIfNoAnimation : animationInDuration : useAnimationOut === "none" ? defaultIfNoAnimation : animationOutDuration;
    const hasAnimation = useDuration > 0;

    // Toggle SSR-rendered overlay´
    const mutateStyle = (background, display) => {
      hasAnimation && (overlay.style.transition = `background ${useDuration}ms`);
      overlay.style.background = background;
      overlay.style.display = display ? "block" : "none";
    };
    if (show) {
      fastdom.mutate(() => {
        const bg = overlay.getAttribute("data-bg");
        mutateStyle(bg ? bg.split(":")[1].trim().replace(";", "") : "transparent", true);
      });
    } else {
      setTimeout(() => fastdom.mutate(() => mutateStyle("transparent", false)), useDuration);
      resetConsentChangeHash();
    }

    // Update `visible` after successful animation
    if (hasAnimation && !show) {
      setTimeout(() => setContextValue(state => ({
        ...state,
        visible: false
      })), useDuration);
    }
    return show ? {
      visible: true,
      animationVisible: true
    } : {
      visible: hasAnimation,
      animationVisible: false
    };
  }, [useBannerAnimationCallback]);
  return {
    overlay,
    toggleOverlay
  };
}
export { useBannerToggleOverlay };